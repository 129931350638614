import * as XLSX from "xlsx";
export default {
  exportToExcel(attr) {
    const table = document.querySelector(`[${attr}]`);
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${attr}.xlsx`);
  },
};
